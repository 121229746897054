import React from "react"

import RxIndicator from "elements/RxIndicator"

import { generatePrice } from "../../Epharmacy/services/computations"

const SearchResultRow = ({ result, handleClick, module }) => {
  return (
    <li onClick={() => handleClick()}>
      <div className="is-flex is-flex-direction-row has-text-weight-bold is-justify-content-space-between is-align-items-center">
        <div className="is-size-6">{result?.DrugName}</div>
        <div><RxIndicator /></div>
      </div>
      <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
        <div className="is-size-7">{result?.brand}</div>
        {module === "epharmacy" && (<div className="is-size-7">Php {generatePrice(result)}</div>)}
      </div>
    </li>
  )
}

export default SearchResultRow
