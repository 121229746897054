import React, { useContext } from "react"

import Layout from "../../Layout/index"
import EpharmacyCart from "../Cart/index"
import Epharmacy from "../index"
import { EpharmacyContext } from "../EpharmacyContext/EpharmacyContext"
import useNovartisProducts from "./hooks/useNovartisProducts"

const EpharmacyOrder = ({ pageContext }) => {
  const { module } = pageContext
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)

  const data = useNovartisProducts()
  let dataMap = data.allAirtableallNovartisProducts.nodes.map(
    (node) => node.data
  )
  // transform data to fit search format
  dataMap = dataMap.map(parseAirtableData)

  return (
    <Layout {...module} pageContext={pageContext}>
      <Epharmacy
        pageContext={pageContext}
        cartContainer={
          <EpharmacyCart
            open={{
              documentsCard: false,
              medicinesCard: true,
            }}
            state={epharmacyState}
            dispatch={epharmacyDispatch}
          />
        }
        data={dataMap}
        lookupKeys={["brand", "DrugName"]}
        moduleState={epharmacyState}
        moduleDispatch={epharmacyDispatch}
      />
    </Layout>
  )
}

const parseAirtableData = (element) => {
  let jsonFormat = {
    brand: [element?.brand],
    DrugName: element?.drugName,
    productTitle: element?.productTitle,
    form: element?.form?.[0],
    molecule: element?.molecule?.[0],
    strength: element?.strength?.[0],
    rxRequired: element?.rxRequired,
    price_list: {
      unitPrice: element?.unitPrice?.[0],
      vat: element?.vat?.[0],
      vatex_unit_price: element?.vatexUnitPrice?.[0],
    },
  }

  return jsonFormat
}

export default EpharmacyOrder
