import React, { Fragment, useContext, useEffect } from "react"
import { Link } from "gatsby"

import CartLayout from "layout/CartLayout"
import ActionButtons from "elements/ActionButtons"
import Search from "../Search/Search"
import AddMedicineModal from "./AddMedicineModal"

import { AppContext } from "../../context/AppContext"
import useNovartisCoupons from "./Order/hooks/useNovartisCoupons"
import useAssistanceCalculatorImages from "./Order/hooks/useAssistanceCalculatorImages"
import { isMedicineAlreadyInCart } from "./services/cart"

const Epharmacy = ({
  pageContext,
  cartContainer,
  data,
  lookupKeys,
  moduleState,
  moduleDispatch,
}) => {
  const { dispatch } = useContext(AppContext)
  const { module } = pageContext
  const novartisCoupons = useNovartisCoupons()
  const imageData = useAssistanceCalculatorImages()
  const assistanceCalculatorImage =
    imageData?.calculatorIcon?.childImageSharp?.fixed
  const moduleName = module?.name

  const handleOnSelect = async (item) => {
    if (isMedicineAlreadyInCart(item, moduleState.medicines))
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `${item.DrugName} already in cart`,
          color: "danger",
        },
      })
    else
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Add medicine to cart",
          isCard: true,
          headerClass: `has-text-primary has-background-light is-size-5`,
          content: (
            <AddMedicineModal
              addToCart={addMedicineToCart}
              medicine={item}
              dispatch={dispatch}
              moduleName={moduleName}
              coupons={novartisCoupons}
              image={assistanceCalculatorImage}
            />
          ),
        },
      })
  }

  const addMedicineToCart = async (item, qty, orderDetails) => {
    const medicineList = [...moduleState.medicines]
    const { totalQuantity, assistanceQuantity, orderTotal } = orderDetails
    medicineList.push({
      ...item,
      qty: qty,
      totalQuantity: totalQuantity,
      assistanceQuantity: assistanceQuantity,
      orderTotal: orderTotal,
    })
    await moduleDispatch({
      type: "SAVE_MEDICINES",
      payload: medicineList,
    })
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Added ${item.DrugName} to cart.`,
        color: "success",
      },
    })
    moduleDispatch({
      type: "SAVE_SELECTED_MEDICINE",
      payload: null,
    })
  }

  const searchComponentProps = {
    placeholder: "Sacubitril-Valsartan (Entresto)",
    dataSource: {
      type: "epharmacy",
      data: data,
      lookupKeys: lookupKeys,
    },
    module: "epharmacy",
    onSelect: handleOnSelect,
    withIcon: true,
    message: {
      color: "warning",
      content: (
        <div>
          <p className="is-size-6 mb-1">
            If you are ordering multiple doses of Sacubitril-Valsartan, please
            search for all your medicines in the search bar and add them to your
            cart.
          </p>
          <p className="is-size-6">
            Suggested Retail Price (SRP) does not reflect medicine assistance.
            Discount or free goods will be applied when you add your medicine to
            cart.
          </p>
        </div>
      ),
    },
  }

  useEffect(() => {
    if (moduleState?.selectedMedicine) {
      handleOnSelect(moduleState?.selectedMedicine)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <CartLayout
        isCentered
        desktop={10}
        fullhd={8}
        cartContainer={cartContainer}
      >
        <div className="mt-1">
          <div className="is-hidden-mobile">
            <Search {...searchComponentProps} />
          </div>
          <div className="is-hidden-desktop is-hidden-tablet">
            <Link to={`${pageContext.mobileSearchPath}`}>
              <Search {...searchComponentProps} />
            </Link>
          </div>
        </div>
      </CartLayout>
      <ActionButtons
        back={{ label: "Back", link: pageContext.backPath }}
        submit={{
          label: "Next",
          link: pageContext.nextPath,
          disabled: moduleState?.medicines?.length === 0,
        }}
      />
    </Fragment>
  )
}

export default Epharmacy
