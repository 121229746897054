import React, { useState, useEffect, Fragment } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

import SearchResults from "./SearchResults"
import Message from "../Elements/Message"

import { fuzzySearch, groupBy } from "./services/fuzzySearch"
import styles from "./utils/search.module.scss"

const Search = ({
  label,
  isRequired,
  type,
  name,
  placeholder,
  dataSource,
  onSelect,
  field = {},
  withIcon,
  handleChange,
  isMobile,
  module,
  helper,
  message,
}) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [searchQueue, setSearchQueue] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOptionHidden, setOptionHidden] = useState(true)

  useEffect(() => {
    setSearchResults([])
    if (searchQuery) {
      setIsLoading(true)
      switch (dataSource.type) {
        case "api":
          break
        case "graphql":
          if (searchQueue) clearTimeout(searchQueue)
          setSearchQueue(
            setTimeout(async () => {
              let searchResults = await fuzzySearch(
                searchQuery,
                dataSource.data,
                dataSource.lookupKeys
              )
              searchResults = groupBy(searchResults, "DrugName")
              setSearchResults(searchResults)
              setIsLoading(false)
            }, 1000)
          )
          break
        case "epharmacy":
          if( searchQueue ) clearTimeout(searchQueue);
          setSearchQueue(
            setTimeout(async () => {
              let searchResults = await fuzzySearch(
                searchQuery,
                dataSource.data,
                dataSource.lookupKeys
              );
              setSearchResults(searchResults);
              setIsLoading(false);
            }, 1000)
          );
          break;
        default:
          break
      }
    }
    //eslint-disable-next-line
  }, [searchQuery])

  const handleValidateMatchDistance = () => {
    if (module === "epharmacy") {
      let values = searchResults[0]
      let distance = Array.isArray(values)
        ? values[0]?.distance
        : values?.distance
      return searchResults.length > 0 && distance !== 1
    } else {
      let values = searchResults[0]?.values
      let distance = Array.isArray(values)
        ? values[0]?.distance
        : values?.distance
      return searchResults.length > 0 && distance !== 1
    }
  }

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Tab") setOptionHidden(true)
  }

  return (
    <Fragment>
      <div className="field mb-0">
        {!!label && (
          <label
            className={classNames("label", {
              "has-text-weight-normal has-text-grey-dark": !isRequired,
            })}
          >
            {label}
            {!!isRequired && <span className="has-text-danger"> *</span>}
            {!!helper?.message && (
              <span
                className={classNames(
                  "help has-text-weight-normal",
                  helper?.className || ""
                )}
              >
                {helper?.message}
              </span>
            )}
          </label>
        )}
        <div className="field-body">
          <div className="field">
            <p
              className={classNames("control has-icons-left", {
                "is-loading": isLoading,
                " has-icons-left": withIcon,
              })}
            >
              <input
                type={type}
                className={classNames(
                  "input",
                  styles["search"]
                )}
                name={name}
                placeholder={placeholder}
                {...field}
                onChange={(event) => {
                  if (field.onChange) field.onChange(event)
                  if (isOptionHidden) setOptionHidden(false)
                  if (handleChange) handleChange(event)
                  setSearchQuery(event.target.value)
                }}
                onKeyDown={handleOnKeyDown}
                readOnly={isMobile}
                // value={searchQuery}
                // onBlur={handleOnBlur}
              />
              {withIcon && (
                <span className={classNames("icon is-small is-left")}>
                  <FontAwesomeIcon icon={faSearch} className={classNames("has-text-primary")} />
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
      {!isOptionHidden && (
        <SearchResults
          searchResults={searchResults}
          isExactMatch={handleValidateMatchDistance()}
          onSelect={onSelect}
          setSearchResults={setSearchResults}
          setSearchQuery={setSearchQuery}
          module={module}
        />
      )}
      {!!message?.content ? (
        <Message color={message?.color} className="mt-1">
          {message?.content}
        </Message>
      ) : null}
    </Fragment>
  )
}

export default Search
